import ReCAPTCHA from "react-google-recaptcha";
import React, { useEffect, useState } from "react";
import { defaultOptions } from "../config/index";
const Captcha = (props) => {
	const [captchaRef, setCaptchaRef] = useState(null);
	useEffect(() => {
		if (captchaRef) {
			captchaRef.reset();
		}
	}, [props, captchaRef]);
	return (
		<p align="center">
			<ReCAPTCHA ref={(r) => setCaptchaRef(r)} sitekey={defaultOptions.googleCaptchaSiteKey} onChange={(value) => props.parentCallback(value)} />
		</p>
	);
};
export default React.memo(Captcha, (prevProps, curProps) => prevProps.reset === curProps.reset);
