import React from "react";
import { Container } from "react-bootstrap";
import RewardsCaim from "../../components/RewardsCaim";
class Home extends React.Component {
	componentDidMount() {}
	render() {
		return (
			<Container fluid className="home">
				<RewardsCaim />
			</Container>
		);
	}
}

export default Home;
