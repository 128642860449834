import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
axios.interceptors.request.use(
	(config) => {
		let userLang = navigator.language || navigator.userLanguage;
		let lang = "en";
		switch (userLang) {
			case "en-GB":
				lang = "en";
				break;
			case "fr-CA":
				lang = "fr";
				break;
			case "es":
				lang = "es";
				break;
			default:
				lang = "en";
		}
		config.headers["Accept-Language"] = lang;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		let refreshToken = false; //store.getState("user").user.refreshToken;
		if (refreshToken && error.response.data.httpCode === 401 && !originalRequest._retry && error.config.url !== `${baseUrl}/s2/auth/refreshtoken`) {
			originalRequest._retry = true;
			try {
				let res = await axios({ method: "POST", url: `${baseUrl}/s2/auth/refreshtoken`, data: { refreshToken: refreshToken } });
				if (res["data"]) {
					if (res.data.httpCode === 200) {
						return axios(originalRequest);
					}
				} else {
					Promise.reject(error);
				}
			} catch (err) {
				Promise.reject(error);
			}
		}
		return Promise.reject(error);
	}
);

class Api {
	post(path, data = null) {
		return this.send(path, "post", data);
	}

	put(path, data = null) {
		return this.send(path, "put", data);
	}

	get(path, data = null) {
		return this.send(path, "get", data);
	}

	delete(path, data = null) {
		return this.send(path, "delete", data);
	}

	send(url, method, data) {
		//console.log("lang", lang);
		let uri = `${baseUrl}${url}`;

		return new Promise((resolve, reject) => {
			let option = { method, url: uri, data: data };

			axios(option)
				.then((response) => {
					console.log("success axios", response);
					return response.data;
				})
				.then((responseData) => {
					// debugger;
					//console.log("data final", responseData);
					resolve(responseData);
				})
				.catch((error) => {
					console.log("error.....", error, error["response"]);
					if (error["response"]) {
						resolve(error.response.data);
					} else {
						resolve(error);
					}
				});
		});
	}
}

const api = new Api();
export default api;
//export default axios;
