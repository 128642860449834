import React from "react";
import { Switch } from "react-router-dom";
import AppRoute from "./AppRoute";
import PublicLayout from "../components/layout/PublicLayout";
import Home from "../screen/Home";
const routes = (
	<>
		<Switch>
			<AppRoute path="/:token" exact component={Home} layout={PublicLayout} />
			<AppRoute path="/" component={Home} layout={PublicLayout} />
		</Switch>
	</>
);
export default routes;
