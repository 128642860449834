import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col, FormControl, Button, Form, Modal } from "react-bootstrap";
import { defaultOptions } from "../config/index";
import Captcha from "./CaptchaClick";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getTokenRewardListAction, postTokenRewardAction } from "../redux/actions";
import { ExclamationCircle, Check2Circle } from "react-bootstrap-icons";
const RewardsCaim = (props) => {
	const { t } = useTranslation();
	let { token } = useParams();
	const searchParams = new URLSearchParams(window.location.search);
	let qry_token=searchParams.get("token")
	let [rewardToken, setRewardToken] = useState(token);
	let [rewardTokenAuoCall, setRewardTokenAutoCall] = useState(qry_token?false:true);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [rewardId, setRewardId] = useState(false);
	let [loader, setLoader] = useState(false);
	let [codeError, setCodeError] = useState({ status: false, msg: "" });
	let [resetCaptcha, setResetCaptcha] = useState(0);
	let [code, setCode] = useState(qry_token?qry_token:"");
	let [rewardList, setRewardList] = useState([]);
	let [errorData, setErrorData] = useState({ code: false, captchValue: false, rewardId: false });
	const [showPopup, setShowPopup] = useState(false);
	useEffect(() => {
		let err = JSON.parse(JSON.stringify(errorData));
		if (captchaValue) {
			err.captchValue = false;
		}
		setErrorData(err);
		// eslint-disable-next-line
	}, [captchaValue]);
	const handleChange = (e) => {
		if (e.target.value.length > 19) {
			return false;
		}
		let length = 0,
			tokenCode = "",
			value = e.target.value.replaceAll("-", "");
		length = value.length;
		for (let i = 0; i < length; i = i + 4) {
			if (i > 0) {
				tokenCode = `${tokenCode}-`;
			}
			tokenCode = `${tokenCode}` + value.substr(i, 4);
		}
		setCode(tokenCode.toUpperCase());
		if (e.target.value.length === 19) {
			let err = JSON.parse(JSON.stringify(errorData));
			err.code = false;
			setErrorData(err);
		}
	};
	const handleClick = () => {
		setRewardToken("");
		let err = JSON.parse(JSON.stringify(errorData));
		let isError = false;
		if (code.length < 19) {
			isError = true;
			err.code = true;
		} else {
			err.code = false;
		}
		if (!captchaValue) {
			err.captchValue = true;
			isError = true;
		} else {
			err.captchValue = false;
		}
		setErrorData(err);
		if (!isError) {
			setRewardToken(code);
			setRewardTokenAutoCall(true)
		}
	};
	useEffect(() => {
		if (rewardToken && rewardTokenAuoCall) {
			setLoader(true);
			getTokenRewardListAction(rewardToken)
				.then((responseData) => {
					if (responseData.httpCode >= 200 && responseData.httpCode <= 299) {
						if (responseData.data.length === 1) {
							claimRewards(responseData.data[0].id);
						} else {
							setLoader(false);
							setRewardList(responseData.data);
						}
					} else if (responseData.httpCode === 406) {
						setCodeError({ status: false, msg: `${t("The reward code")} <strong>${rewardToken}</strong>, ${t("is already used")}` });
						setLoader(false);
						setResetCaptcha(resetCaptcha + 1);
						setRewardToken("");
						setShowPopup(true);
					}else if (responseData.httpCode === 498) {
						setCodeError({ status: false, msg: `90 days claim period is expired for redemption code<br/> <strong>${rewardToken}</strong>` });
						setLoader(false);
						setResetCaptcha(resetCaptcha + 1);
						setRewardToken("");
						setShowPopup(true);
					} else {
						setCodeError({ status: false, msg: `${t("The reward code")} <strong>${rewardToken}</strong>, ${t("is invalid")}` });
						setLoader(false);
						setResetCaptcha(resetCaptcha + 1);
						setRewardToken("");
						setShowPopup(true);
					}
				})
				.catch((err) => {
					console.log("err", err, err.httpCode);
					if (err.httpCode === 406) {
						setCodeError({ status: false, msg: `${t("The reward code")} <strong>${rewardToken}</strong>, ${t("is already used")}` });
						setLoader(false);
						setResetCaptcha(resetCaptcha + 1);
						setRewardToken("");
						setShowPopup(true);
					} else {
						setCodeError({ status: false, msg: `${t("The reward code")} <strong>${rewardToken}</strong>, ${t("is invalid")}` });
						setLoader(false);
						setResetCaptcha(resetCaptcha + 1);
						setRewardToken("");
						setShowPopup(true);
					}
				});
		}
		// eslint-disable-next-line
	}, [rewardToken]);
	const claimRewards = (rId) => {
		setLoader(true);
		const formData = { rewardId: rId };
		postTokenRewardAction(rewardToken, formData)
			.then((respData) => {
				if (respData.httpCode >= 200 && respData.httpCode <= 299) {
					setCodeError({ status: true, msg: `${t("The reward code")} <strong>${rewardToken}</strong>, ${t("is redeemed successfully")}` });
				} else if (respData.httpCode === 406) {
					setCodeError({ status: false, msg: `${t("The reward code")} <strong>${rewardToken}</strong>, ${t("is already used")}` });
				} else {
					setCodeError({ status: false, msg: `${t("The reward code")} <strong>${rewardToken}</strong>, ${t("is invalid")}` });
				}
				setLoader(false);
				setResetCaptcha(resetCaptcha + 1);
				setShowPopup(true);
			})
			.catch((er) => {
				if (er.httpCode === 406) {
					setCodeError({ status: false, msg: `${t("The reward code")} <strong>${rewardToken}</strong>, ${t("is already used")}` });
				} else {
					setCodeError({ status: false, msg: `${t("The reward code")} <strong>${rewardToken}</strong>, ${t("is invalid")}` });
				}
				setLoader(false);
				setResetCaptcha(resetCaptcha + 1);
				setShowPopup(true);
			});
	};
	const handleClaimClick = () => {
		if (rewardId) {
			claimRewards(rewardId);
		} else {
			let err = JSON.parse(JSON.stringify(errorData));
			err.rewardId = true;
			setErrorData(err);
		}
	};
	const handleClosePopup = () => {
		setShowPopup(false);
		setRewardToken(null);
		setCode("");
		setCodeError({ status: false, msg: "" });
		window.location.href = "/";
	};
	const changeRewardListHandler = (e) => {
		setRewardId(e.target.value);
		if (e.target.value) {
			let err = JSON.parse(JSON.stringify(errorData));
			err.rewardId = false;
			setErrorData(err);
		}
	};
	return (
		<Container>
			<Row className="justify-content-md-center">
				<Col md={10} xs={12} className="text-center pt-2 pb-2">
					<img src={`${defaultOptions.imageBaseUrl}/reward_yourself.png`} alt="rewards box" />
				</Col>
				<Col md={10} xs={12} className="text-center pt-2 pb-2">
					<h2 className="section-heading">
						<Trans>reward yourself today by entering</Trans> <br />
						<Trans>your redemption code below</Trans>
					</h2>
				</Col>
				<Col md={6} xs={12} className="text-center pt-2 pb-2">
					{rewardList.length > 1 || token ? (
						<>
							<Form.Control as="select" aria-label={t("Choose Your Reward")} onChange={changeRewardListHandler}>
								<option>{t("Choose Your Reward")}</option>
								{rewardList.map((v, i) => {
									return (
										<option key={`opt-${i}`} value={v.id}>
											{v.name}
										</option>
									);
								})}
							</Form.Control>
							{errorData.rewardId ? (
								<span className="align-error text-center">
									<Trans>Please select a reward</Trans>.
								</span>
							) : (
								""
							)}
						</>
					) : (
						<>
							<FormControl value={code} id="rcode" size="lg" placeholder="XXXX-XXXX-XXXX-XXXX" onChange={handleChange} />
							{errorData.code ? (
								<span className="align-error text-center">
									<Trans>Please enter a valid redemption code</Trans>.
								</span>
							) : (
								""
							)}
						</>
					)}
				</Col>
				{rewardList.length > 1 || token ? (
					<Col md={8} className="text-center pb-5 pt-2">
						<Button disabled={loader} size="lg" onClick={handleClaimClick} className="btn-main large light-btn light-border">
							{!loader ? <Trans>Claim Reward</Trans> : <Spinner animation="border" variant="light" />}
						</Button>
					</Col>
				) : (
					<>
						<Col md={8} xs={12} className="text-center pt-2 pb-2">
							<Captcha reset={resetCaptcha} parentCallback={setCaptchaValue} />
							{errorData.captchValue ? (
								<span className="align-error text-center">
									<Trans>Please check the captcha checkbox</Trans>.
								</span>
							) : (
								""
							)}
						</Col>

						<Col md={8} className="text-center pb-5 pt-2">
							<Button disabled={loader} size="lg" onClick={handleClick} className="btn-main large light-btn light-border">
								{!loader ? <Trans>Continue</Trans> : <Spinner animation="border" variant="light" />}
							</Button>
						</Col>
					</>
				)}
			</Row>
			<Modal aria-labelledby="contained-modal-title-vcenter" centered show={showPopup}>
				<Modal.Body>
					<Row md={1}>
						<Col className={`text-center ${codeError.status ? "icon-success" : "icon-warning"}`}>{codeError.status ? <Check2Circle /> : <ExclamationCircle />}</Col>
						<Col className="text-center">
							<h4>{codeError.status ? t("Success") : t("Sorry")}</h4>
						</Col>
						<Col className="text-center">
							<p dangerouslySetInnerHTML={{ __html: codeError.msg }}></p>
						</Col>
						<Col className="text-center">
							<Button className="reward-popup-btn" id="reward-popup-btn" onClick={handleClosePopup}>
								OK
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</Container>
	);
};
export default RewardsCaim;
