import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/theme.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import routes from "./routing/Routing";
import "./child-theme/css/theme-child.css";
import { ToastContainer } from "react-toastify";

const App = () => {
	return (
		<>
			<Router>{routes}</Router>
			<ToastContainer />
		</>
	);
};
export default App;
