import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { defaultOptions } from "../../config/index";
const Footer = () => {
	return (
		<Container fluid className="footer">
			<Row md={1} sm={1} xs={1}>
				<Col className="text-center pt-2 pb-2">
					<img alt="logo" src={`${defaultOptions.imageBaseUrl}/3tl-rewards.png`}></img>
				</Col>
				<Col className="text-center pt-2 pb-2">
					<h5>Powered by</h5>
					<img alt="logo" src={`${defaultOptions.imageBaseUrl}/3tl-home-logo.png`}></img>
				</Col>
			</Row>
		</Container>
	);
};
export default Footer;
