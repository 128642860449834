import React from "react";
import { Navbar } from "react-bootstrap";
import { defaultOptions } from "../../config/index";
const Header = () => {
	return (
		<Navbar expand="lg" fixed="top" bg="white" className="header">
			<Navbar.Brand href="#">
				<img alt="logo" src={`${defaultOptions.imageBaseUrl}/3tl-rewards.png`}></img>
			</Navbar.Brand>
		</Navbar>
	);
};

export default Header;
