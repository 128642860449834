import { CURRENT_SELECTED_MENU } from "./types";
import api from "../../api/api.js";
export const getTokenRewardListAction = (token) => {
	return api.get(`/s1/reward/${token}`, null);
};

export const postTokenRewardAction = (token, body) => {
	return api.post(`/s1/reward/${token}`, body);
};
export const saveSelectedMenu = (data) => ({
	type: CURRENT_SELECTED_MENU,
	payload: data,
});
