import { CURRENT_SELECTED_MENU } from "../actions/types";
let initialState = {
	user: {},
	language: "en",
	config: {},
	currentMenu: "",
};
export default function(state = initialState, action) {
	switch (action.type) {
		case CURRENT_SELECTED_MENU:
			return { ...state, currentMenu: action.payload };
		default:
			return state;
	}
}
